<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'

import FormBase from '@/components/mixins/FormBase'

import TextInput from '@/components/form/Inputs/TextInput'
import TextareaInput from '@/components/form/Inputs/TextareaInput'
import OrderDetailsBlock from './tabs/general/OrderDetailsBlock'
import PrintMealList from './PrintMealList'

export default {
    components: { OrderDetailsBlock, TextInput, TextareaInput, PrintMealList },
    mixins: [FormBase],
    data() {
        return {
            api: RestApiType.ORDERS,
            mapper: MapperType.ORDER_FORM,
            form: {},
            entity: 'labels.order',
            listRoute: routeType.ORDER_LIST,
            isValid: false,
        }
    },
    computed: {
        title() {
            return this.form?.identifier ? this.form.identifier : null
        },
    },
}
</script>

<template>
    <base-form
        ref="form"
        v-model="form"
        :api="api"
        :mapper="mapper"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        :disable-delete-button="true"
        @valid="isValid = $event"
        @updated="updated"
        @cancelled="cancelled"
    >
        <v-row class="pt-2">
            <v-col cols="12" sm="4">
                <order-details-block :model="form" :for-company="true">
                    <v-divider class="my-2"></v-divider>
                    <div class="text-body-1 g-26--text font-weight-medium mb-1">
                        {{ $t('labels.comment') }}
                    </div>
                    <textarea-input
                        v-model="form.comment"
                        v-validate.immediate="'space'"
                        name="comment"
                        :data-vv-as="$t('labels.comment')"
                        :error-messages="errors.collect('comment')"
                    ></textarea-input>
                    <div class="text-body-1 g-26--text font-weight-medium mb-1">
                        {{ $t('labels.cost_code') }}
                    </div>
                    <text-input
                        v-model="form.costCode"
                        v-validate.immediate="'space'"
                        name="cost_code"
                        :error-messages="errors.collect('cost_code')"
                        :data-vv-as="$t('labels.cost_code')"
                    ></text-input>
                    <div class="d-flex justify-end mt-3">
                        <print-meal-list :order="form"></print-meal-list>
                    </div>
                </order-details-block>
            </v-col>
        </v-row>
    </base-form>
</template>
